















































































































import type { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import BaseDialog from '@/components/BaseDialog.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  EOperatorInvoiceType,
  OperatorInvoiceTypeNaming
} from '@/api/schema';

import {
  getSuperAdminOperators,
  generateInvoiceOperator
} from '@/api/SuperAdmin';

import SuccessPopupCheck from '@/assets/images/notification/success-popup-check.svg?inline';
import { DateTime } from 'luxon';

const defaultFormData = {
  type: null,
  operatorId: null,
  amountUsdt: null,
  invoiceNumber: null
};

export default {
  name: 'SuperAdminInvoicesCreateFile',
  components: { VExtendedDataPicker, BaseDialog, SuccessPopupCheck },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      formValid: false,
      showDatePeriod: false,
      showComplete: false,
      date: {
        from: null,
        to: null
      },
      loading: false,
      operatorsList: {
        data: [],
        loading: false
      },
      formData: { ...defaultFormData }
    };
  },
  computed: {
    rules(): any {
      return {
        type: [vr.required],
        operatorId: [vr.required],
        amountUsdt: [vr.validNumber],
        period: [vr.required],
        invoiceNumber: [vr.required]
      };
    },
    listTypes(): { [key: string]: string }[] {
      return Object.entries(OperatorInvoiceTypeNaming).map(
        ([value, label]) => ({ label, value })
      );
    },
    isShowAmountField(): boolean {
      return !(
        this.formData.type === EOperatorInvoiceType.INVOICE ||
        this.formData.type === null
      );
    }
  },
  watch: {
    value(show: boolean): void {
      if (show && !this.operatorsList.data.length) {
        this.getOperatorsList();
      }
    }
  },
  methods: {
    getOperatorsList(): void {
      this.operatorsList.loading = true;
      getSuperAdminOperators()
        .then((response) => {
          this.operatorsList.data = response;
        })
        .finally(() => {
          this.operatorsList.loading = false;
        });
    },

    closeDialog(): void {
      this.$emit('input', false);

      setTimeout(() => {
        this.showComplete = false;
        this.date = null;
        this.formData = { ...defaultFormData };
      }, 300);
    },

    submitHandler(): void {
      if (!this.formValid) return;

      this.loading = true;

      generateInvoiceOperator({
        ...this.formData,
        fromDate: DateTime.fromISO(this.date.from)
          .startOf('month')
          .toFormat('yyyy-MM-dd'),
        toDate: DateTime.fromISO(this.date.to)
          .endOf('month')
          .toFormat('yyyy-MM-dd')
      })
        .then(() => {
          this.showComplete = true;
          this.$emit('update-list');
        })
        .catch((error) => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
